import Header from "../../components/Header";
import Navbar from "../../components/Navbar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

function GDPR() {
    const ExternalLink = ({ href, children }) => {
      return (
          <a href={href} target="_blank" rel="noopener noreferrer" className="external-link">
              {children} <FontAwesomeIcon icon={solid('external-link-alt')} />
          </a>
      );
    }

    return (
        <div>
        <Header />
        <Navbar />
        <div className="site-blocks-cover">
          <div className="container" style={{paddingTop: "80px"}}>
            <div className="row align-items-center justify-content-center">
              <div className="col-md-12">
                <div className="row mb-4">
                  <div className="d-flex align-items-start flex-column">
                    <h1>GDPR Commitment</h1>

                    <p>Li10 Labs UG (”The Company“) is committed to compliance with the <ExternalLink href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A02016R0679-20160504">General Data Protection Regulation</ExternalLink>, which went into effect May 25, 2018. The GDPR is the most comprehensive EU data privacy law in decades. It is designed to give EU citizens more control over their data and seeks to unify a number of existing privacy and security laws under one comprehensive law.</p>

                    <p>Our customers can trust that The Company has made GDPR a priority and has devoted significant resources toward our efforts to comply with GDPR. This page outlines our approach and progress to date.</p>

                    <h2>What The Company is doing</h2>
                    <p>Like many other global software companies, The Company has rolled out its company-wide GDPR compliance strategy. The Company appreciates that our customers have requirements under GDPR that are directly impacted by their use of The Company products and services, and The Company is committed to helping our customers fulfill their requirements under GDPR and local law.</p>

                    <p>Below are a few examples of initiatives The Company has committed to in order to satisfy GDPR requirements that apply to both The Company and our customers:</p>

                    <li>Committing to follow any additional security and privacy measures required under GDPR.</li>
                    <li>Where we are transferring data outside of the EU, committing to appropriate data transfer mechanisms as required by GDPR.</li>
                    <li>Assisting with respect to security and privacy of processing, notifying regulators of breaches, and promptly   communicating any breaches to customers and user.</li>
                    <li>Assisting with data processing security and privacy requirements, notifying regulators of personal data breaches and promptly communicating any such breaches to our customers and end-users.</li>
                    <li>Ensuring The Company staff that access and process The Company customer personal data have been trained in    handling that data and are bound to maintain the confidentiality and security of that data.</li>
                    <li>Committing to carrying out data impact assessments and consulting with EU regulators where appropriate.</li>

                    <br/><br/>

                    <h2>GDPR Q&A</h2>
                    <h2>Does The Company process Personal Data of its customers?</h2>
                    <p>Yes, The Company processes customer Personal Data to provide the products and services and for other limited purposes enumerated in our Privacy Policy.</p>

                    <h2>Where does The Company send my data?</h2>
                    <p>Our goal is to provide our customers with secure, fast, and reliable services. As a provider of global services, we run our services with common operational practices and features across multiple jurisdictions.</p>

                    <p>The Company stores customer cloud data in its Amazon's AWS data centers located in Europe. </p>

                    <h2>Who can access my data?</h2>
                    <p>The Company allows employees and contractors located in Germany and Greece access to certain data from European Union-located Li10 Labs Accounts for product development, customer and technical support purposes only.</p>

                    <h2>Where is a list of The Company's third-party data processors?</h2>
                    <p>The Company maintains an up-to-date list of data suprocessors authorized to process customer data in support of The Company services.</p>

                    <h2>The Company Commitment</h2>
                    <p>The Company is committed to customer success and the protection of customer data, which is why our customers can count on our commitment to GDPR compliance.</p>

                    <p>Fulfilling our privacy and data security commitments is important to us. So we’re glad to help you prepare for all the changes the GDPR brings. This page will be revised to reflect GDPR-related information as it becomes available. If you have any questions about how The Company is addressing GDPR compliance, please send us a note at matt@li10.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
        </div>
    );
}
export default GDPR;
