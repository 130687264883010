import React from "react"
import about from "../assets/about.png"

function About() {
  return (
    <div className="site-section bg-light" id="about">
      <div className="container">
        <div className="row mb-5">
          <div className="col-12 text-center">
            <h2 className="section-title mb-3">About Us</h2>
          </div>
        </div>
        <div className="row mb-5 aos-init aos-animate" data-aos="fade-up">
          <div className="col-lg-6">
            <img src={about} title="About Us" alt="About Us" className="img-fluid mb-5 mb-lg-0 rounded shadow"></img>
          </div>
          <div className="col-lg-5 ml-auto pl-lg-5">
            <h2 className="text-black mb-4">What is Li10?</h2>
            <p className="mb-4">At Li10, we are dedicated to making a positive impact on our planet by addressing the growing concern of carbon emissions generated by cloud computing. Our mission is to provide sustainable and eco-friendly solutions to businesses worldwide, helping them reduce their carbon footprint and contribute to a greener future.</p>
            <p className="mb-4">Founded in 2022, our team of passionate technologists and environmentalists recognized the need for a more responsible approach to cloud computing. We understand that as technology evolves and businesses increasingly rely on cloud services, it is our collective responsibility to ensure the sustainability of our digital infrastructure.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About;
