import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

// Generate a nonce value
const nonceValue = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

// Define your public URLs
const publicUrls = [
  'https://li10.com',
  'https://www.li10.com', // Add more public URLs as needed
];

// Concatenate the public URLs into a single string
const publicUrlsString = publicUrls.join(' ');

// Wait for the document to be ready before manipulating it
document.addEventListener('DOMContentLoaded', () => {
  // Create a new meta tag with the CSP directive including the nonce value
  const cspMetaTag = document.createElement('meta');
  cspMetaTag.setAttribute('http-equiv', 'Content-Security-Policy');
  cspMetaTag.setAttribute('content', `
    default-src 'self';
    connect-src 'self' https://api.li10.com/contact;
    img-src 'self' ${publicUrlsString} localhost:3000 data:;
    script-src 'self' ${publicUrlsString} 'nonce-${nonceValue}';
    style-src 'self' fonts.googleapis.com https://cdn.jsdelivr.net 'unsafe-inline';
    font-src 'self' https://fonts.gstatic.com 'nonce-${nonceValue}';
  `);

  // Append the new meta tag to the document head
  document.head.appendChild(cspMetaTag);
});

// Render the React app
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
