import Header from "../components/Header";
import Navbar from "../components/Navbar";
import Cover from "../components/Cover";
import Solutions from "../components/Solutions";
import {Pricing} from "../components/Pricing";
import About from "../components/About";
import Footer from "../components/Footer";
import Contact from "../components/Contact";

function Home() {
    return (
        <div>
        <Header />
        <Navbar />
        <Cover />
        <Solutions />
        <Pricing />
        <About />
        <Contact />
        <Footer />
        </div>
    );
}
export default Home;
