import React from "react"
// import { useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'



export const Pricing = (props) => {
  // const THRESHOLD_IN_EUROS = "5.000 €"
  // const THRESHOLD_IN_USD = "$5,000"
  // const PRICE_IN_EUROS = "10 €"
  // const PRICE_IN_USD = "$10"

  // const [priceThreshold, setPriceThreshold] = useState(THRESHOLD_IN_EUROS)
  // const [price, setPrice] = useState(PRICE_IN_EUROS)

  // function handleUnitChange(e) {
  //   if (priceThreshold === THRESHOLD_IN_EUROS) {
  //     setPriceThreshold(THRESHOLD_IN_USD)
  //     setPrice(PRICE_IN_USD)
  //   } else {
  //     setPriceThreshold(THRESHOLD_IN_EUROS)
  //     setPrice(PRICE_IN_EUROS)
  //   }
  // }

  // render() {
  return (
    <div className="site-section bg-light" id="pricing">
      <div className="container">
        <div className="row mb-5">
          <div className="col-12 text-center">
            <h2 className="section-title mb-3">Pricing</h2>
          </div>
        </div>
        <div className="row align-items-stretch" style={{backgroundColor: "#e9ecef", border: "1px solid #dee2e6", padding: "20px", marginBottom: "20px"}}>
          <center><h2>Li10 CO2 Monitor</h2></center>
          <div className="col-md-8 col-lg-6 mb-4 mb-lg-4 aos-init aos-animate" data-aos="fade-up">
            <div className="unit-4 d-block pricing-box-height">
              <div className="mb-3">
                <center><h3>Starter: Free</h3></center>
              </div>

              <div>
                <p>
                  <FontAwesomeIcon icon={solid('check')} /> &nbsp;Free if your total monthly cloud spend is under $5000 <br />
                  <FontAwesomeIcon icon={solid('check')} /> &nbsp;Monitor your cloud carbon emissions<br />
                  <FontAwesomeIcon icon={solid('check')} /> &nbsp;Calculate your Cloud Sustainability Score<br />
                  <FontAwesomeIcon icon={solid('check')} /> &nbsp;Single-user access
                </p>
              </div>

              <div style={{position: "absolute", bottom: "4%", width: "90%", textAlign: "center"}}>
                <a href="https://app.li10.com" className="btn btn-primary mr-2 mb-2"><b>Register now</b></a>
              </div>

            </div>
          </div>

          <div className="col-md-8 col-lg-6 mb-4 mb-lg-4 aos-init aos-animate" data-aos="fade-up">
            <div className="unit-4 d-block pricing-box-height">
              <div className="mb-3">
                <center><h3>Enterprise: $10 per month per AWS account</h3></center>
              </div>
              <div className="mb-3">
                <p>
                  <FontAwesomeIcon icon={solid('check')} /> &nbsp;For customers with a total monthly cloud spend above $5000<br />
                  <FontAwesomeIcon icon={solid('check')} /> &nbsp;Same features as the Starter pack, plus: <br />
                  <FontAwesomeIcon icon={solid('check')} /> &nbsp;Multi-user access <br />
                  <FontAwesomeIcon icon={solid('check')} /> &nbsp;SSO access with your corporate Identify Provider (e.g. Okta)
                </p>
              </div>
              <div style={{position: "absolute", bottom: "4%", width: "90%", textAlign: "center"}}>
                <a href="https://app.li10.com" className="btn btn-primary mr-2 mb-2"><b>Register now</b></a>
              </div>
            </div>
          </div>
        </div>

        <div className="row align-items-stretch" style={{backgroundColor: "#e9ecef", border: "1px solid #dee2e6"}}>
        <center><h2>Li10 Governance</h2></center>
          <div className="col-md-8 col-lg-6 mb-4 mb-lg-4 aos-init aos-animate" data-aos="fade-up">
            <div className="unit-4 d-block pricing-box-height">
              <div className="mb-3">
                <center><h3>Starter: Free</h3></center>
              </div>

              <div>
                <p>
                  <FontAwesomeIcon icon={solid('check')} /> &nbsp;Free if your total monthly cloud spend is under $5000 <br />
                  <FontAwesomeIcon icon={solid('check')} /> &nbsp;Access to repository with Infrastructure-as-Code (IaC), pipeline, basic set of policies<br />
                  <FontAwesomeIcon icon={solid('check')} /> &nbsp;Single-user access to dashboard (currently on waiting list)<br />
                  <FontAwesomeIcon icon={solid('check')} /> &nbsp;Access to private Github repository
                </p>
              </div>

              <div style={{position: "absolute", bottom: "4%", width: "90%", textAlign: "center"}}>
                <a href="https://app.li10.com" className="btn btn-primary mr-2 mb-2"><b>Register now</b></a>
              </div>

            </div>
          </div>

          <div className="col-md-8 col-lg-6 mb-4 mb-lg-4 aos-init aos-animate" data-aos="fade-up">
            <div className="unit-4 d-block pricing-box-height">
              <div className="mb-3">
                <center><h3>Enterprise: 1% of your cloud bill</h3></center>
              </div>
              <div className="mb-3 ">
                <p>
                  <FontAwesomeIcon icon={solid('check')} /> &nbsp;For customers with a total monthly cloud spend above $5000<br />
                  <FontAwesomeIcon icon={solid('check')} /> &nbsp;Same features as the Starter pack, plus: <br />
                  <FontAwesomeIcon icon={solid('check')} /> &nbsp;Multi-user access to dashboard (currently on waiting list) <br />
                  <FontAwesomeIcon icon={solid('check')} /> &nbsp;SSO access with your corporate Identify Provider (e.g. Okta)<br />
                  <FontAwesomeIcon icon={solid('check')} /> &nbsp;Access to private Github repository <br />
                  <FontAwesomeIcon icon={solid('check')} /> &nbsp;Customer Pull Requests reviewed by Li10 engineers <br />
                  <FontAwesomeIcon icon={solid('check')} /> &nbsp;1-hour weekly advisory with a Li10 engineer
                </p>
              </div>
              <div style={{position: "absolute", bottom: "4%", width: "90%", textAlign: "center"}}>
                <a href="https://app.li10.com" className="btn btn-primary mr-2 mb-2"><b>Register now</b></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
  // }
}

// export default Pricing;
