import Header from "../../components/Header";
import Navbar from "../../components/Navbar";

function PrivacyPolicy() {
    return (
        <div>
        <Header />
        <Navbar />
        <div className="site-blocks-cover">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-12">
                <div className="row mb-4">
                  <div className="d-flex align-items-start flex-column">
                    <h1>Legal Center</h1>
                    <p>If you can any question, please <a href="/#contact">Contact Us</a></p>
                    <div>At Li10, our mission is to help organizations reduce the carbon footprint associated with cloud computing. And we are doing so with the utmost respect for your business, your privacy and applicable laws. The following documents clarifies our commitment and responsabilities.
                      <ul><a href="/legal/privacy-policy">Privacy Policy Statement</a></ul>
                      <ul><a href="/legal/terms-and-conditions">Terms & Conditions</a></ul>
                      <ul><a href="/legal/gdpr">GDPR Commitment</a></ul>
                      <ul><a href="/legal/data-subprocessor">Data Subprocessors</a></ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
    );
}
export default PrivacyPolicy;
