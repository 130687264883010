import React from "react";
import temp from "../assets/li10-footprint.png"

class Cover extends React.Component {
    render() {
      return (
        <div className="site-blocks-cover">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-12">
                <div className="d-none d-lg-block">
                  <img src={temp} title="Li10 footprint" alt="Li10 footprint" className="img-fluid img-absolute"></img>
                </div>
                <div className="row mb-4">
                  <div className="col-lg-4 mr-auto">
                    <h1>Lighten your carbon footprint, optimize your cloud!</h1>
                    <p>We are dedicated to help organizations reduce the carbon footprint associated with cloud computing.</p>
                    <p><li><a href="/#co2-monitor" className="hover-underline"><b>Li10 CO2 Monitor</b></a> measures your cloud workloads environmental footprint and rates it with
                    a <a href="/#cloud-sustainability-score" className="hover-underline"><b>Cloud Sustainability Score</b></a> on a real-time dashboard.</li></p>
                    <p><li><a href="/#li10-governance" className="hover-underline"><b>Li10 Governance</b></a> automates cloud infrastructure audit and remediation
                    to improve your cloud governance posture, reduce risk, cost and environmental footprint.</li></p>
                    <div>
                      <a href="https://app.li10.com" className="btn btn-primary mr-2 mb-2"><b>Get started now!</b></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
}

export default Cover;
