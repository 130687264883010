import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import './li10.css';
import 'aos/dist/aos.css';
import AOS from "aos";
import Home from "./pages/Home";
import PageNotFound from "./pages/PageNotFound";
import TermsAndConditions from "./pages/legal/TermsAndConditions"
import GDPR from "./pages/legal/GDPR"
import DataSubProcessor from "./pages/legal/DataSubprocessors"
import PrivacyPolicy from "./pages/legal/PrivacyPolicy";
import Legal from "./pages/legal/Legal"

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/legal/" element={<Legal />} />
      <Route path="/legal/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/legal/gdpr" element={<GDPR />} />
      <Route path="/legal/data-subprocessor" element={<DataSubProcessor />} />
      <Route path="/legal/privacy-policy" element={<PrivacyPolicy/>} />
      <Route path="*" element={<PageNotFound/>} status={404} />
    </Routes>
  );
}

export default App;
