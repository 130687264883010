import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import monitor from "../assets/monitor.png"
import score from "../assets/score.png"
import { faGithub } from '@fortawesome/free-brands-svg-icons';

class Solutions extends React.Component {

  resolveIcon = (icon) => {
    const icons = {
      'eye': solid('eye'),
      'scale-unbalanced': solid('scale-unbalanced'),
      'robot': solid('robot'),
      'user': solid('user')
    };
    return icons[icon] || solid('eye');
  }

  ExternalLink = ({ href, children }) => {
    return (
        <a href={href} target="_blank" rel="noopener noreferrer" className="external-link">
            {children} <FontAwesomeIcon icon={solid('external-link-alt')} />
        </a>
    );
}

  SolutionHeader = ({ icon, title }) => {
    const resolvedIcon = this.resolveIcon(icon);

    return (
      <div className="unit-4-icon mb-3">
        <span className="icon-wrap">
          <span className="text-primary">
            <FontAwesomeIcon icon={resolvedIcon} />
          </span>
        </span>
        <h2>{title}</h2>
      </div>
    );
  }

    render() {
      return (
        <div className="site-section bg-light" id="solutions">
        <div className="container">
          <div className="row mb-5">
            <div className="col-12 text-center">
              <h2 className="section-title mb-3">Solutions</h2>
            </div>
          </div>
          <div className="row align-items-stretch" >
            <div className="col-md-12 col-lg-12 mb-12 mb-lg-12 aos-init aos-animate" data-aos="fade-up" id="co2-monitor">
              <div className="unit-4 d-block solution-box-height">

              <this.SolutionHeader icon="eye" title="Li10 CO2 Monitor" />

              <p>
                The <b>Li10 CO2 Monitor</b> measures the sustainability impact of your cloud environment and provides a dashboard to analyze it.
              </p>

              <div style={{display: "flex", gap: "10px"}}>
                <img src={monitor} title="Measure and Analyze" alt="Measure and Analyze" className="img-fluid"></img>
                <div style={{display: "block"}}>
                  <p>
                    Our dashboard gives you a clear understanding of your cloud carbon footprint.
                  </p>
                  <p>You will be able see:
                     <li>your CO2 emissions</li>
                     <li>your electricity consumption in kwh</li>
                     <li>the associated cloud cost</li>
                     <li>your <a href="/#cloud-sustainability-score" className="hover-underline"><b>Cloud Sustainability Score</b></a></li>
                  </p>
                  <p>To further identify your emission sources, you can filter your data by:
                    <li>Time span</li>
                    <li>AWS Account</li>
                    <li>AWS Service</li>
                    <li>AWS Region</li>
                  </p>
                  <p>
                To calculate your cloud emissions, we use:
                <li><this.ExternalLink href="https://docs.aws.amazon.com/cur/latest/userguide/what-is-cur.html">AWS data</this.ExternalLink> from your Billing Account</li>
                <li>the Software Carbon Intensity methodology <br />defined by the <this.ExternalLink href="https://greensoftware.foundation/">Green Software Foundation</this.ExternalLink></li>
                <li>a library from the <this.ExternalLink href="https://www.cloudcarbonfootprint.org/">Cloud Carbon Footprint</this.ExternalLink> project</li>
              </p>

                </div>
              </div>


              <div>
                <b className="h4">How is our approach different?</b><br />
                <ul>
                  <li>we use the open, unbias and cloud agnostic methodology from the GSF</li>
                  <li>as a SaaS, there is no software to install and manage</li>
                  <li>our dashboard runs as a modern serverless application which generates less emissions than a dedicated container or instance-based infrastructure</li>
                </ul>
              </div>

              <p>
                <b className="h4">What about other IT providers?</b><br />
                We are planning to integrate with Azure and GCP but please let us know your priorities!
              </p>

              <p>
                <b className="h4">Is it secure?</b><br />
                Our solution implements the industry security best practices. The on-boarding CloudFormation template to load in your Billing account grants us the strict minimum permissions to access the data required to calculate the emissions.
              </p>
              </div>
            </div>

            <div className="col-md-12 col-lg-12 mb-12 mb-lg-12 aos-init aos-animate" data-aos="fade-up" id="cloud-sustainability-score">
              <div className="unit-4 d-block solution-box-height">
              <this.SolutionHeader icon="scale-unbalanced" title="Cloud Sustainability Score" />

              <p className="mb-5">
              The <b>Li10 CO2 Monitor</b> calculates a <b>Cloud Sustainability Score</b> to easily understand of your cloud environment's sustainability posture. This score acts as a benchmark to establish sustainability objectives and foster ongoing enhancements.
              </p>
              <img src={score} title="Cloud Sustainability Score" alt="Cloud Sustainability Score" className="img-fluid-score"></img>
              <p>
              By analyzing the ratio of emissions to cloud spend, the score is applicable across industries and environments of any magnitude. Your score is computed on a daily basis, automatically reflecting any changes and advancements in your environment.
              </p>
              <p>
              Please note that the grading system is presently in beta, and future updates may be introduced to further refine and improve its accuracy.
              </p>
              </div>
            </div>

            <div className="col-md-12 col-lg-12 mb-12 mb-lg-12 aos-init aos-animate" data-aos="fade-up" id="li10-governance">
              <div className="unit-4 d-block solution-box-height">

              <this.SolutionHeader icon="robot" title="Li10 Governance" />

              <p className="mb-5"><b>Li10 Governance</b> is an advanced cloud governance solution designed for enterprises seeking real-time compliance
              and auditing capabilities for cloud infrastructure. Our <this.ExternalLink href="https://github.com/li10labs/li10-governance">open source solution <FontAwesomeIcon icon={faGithub} /></this.ExternalLink> can be deployed as a SaaS or self-hosted following industry best-practices,
              and delivers immediate benefits such as cost reduction, heightened security, and a reduced environmental footprint. Li10 Governance occupies a unique
              position in the market, balancing flexibility, ease of operation, and cost-effectiveness.</p>
              <p>Built upon the robust open source project <this.ExternalLink href="https://github.com/cloud-custodian/cloud-custodian">Cloud Custodian</this.ExternalLink>, Li10 Governance utilizes its versatile policy-as-code engine to streamline cloud
              infrastructure governance. Our solution is enhanced with a suite of field-tested policies, as well as infrastructure-as-code
              and pipelines that simplify its operation. With our solution, DevOps engineers can effectively maintain and enhance
              their organization's cloud governance posture, ensuring optimal performance and compliance.</p>

              <table style={{width:"100%", borderCollapse: "collapse", border: "1", cellpadding: "5", cellspacing: "0"}}>
                <thead>
                    <tr>
                        <th>Feature</th>
                        <th style={{backgroundColor: "#e6f4ea"}}>Li10 Governance</th>
                        <th>Cloud Custodian</th>
                        <th>AWS Config</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Real-time Audit and Compliance</td>
                        <td style={{backgroundColor: "#e6f4ea"}}>✅</td>
                        <td>✅</td>
                        <td>✅</td>
                    </tr>
                    <tr>
                        <td>Policy-as-Code</td>
                        <td style={{backgroundColor: "#e6f4ea"}}>✅ YAML-format policies</td>
                        <td>✅ YAML-format policies</td>
                        <td>🚧 Requires infrastructure as code and Lambda code development for customizations</td>
                    </tr>
                    <tr>
                        <td>License</td>
                        <td style={{backgroundColor: "#e6f4ea"}}>✅ Open-source</td>
                        <td>✅ Open-source</td>
                        <td>🔒 Closed-source</td>
                    </tr>
                    <tr>
                        <td>Ready-Made Policies</td>
                        <td style={{backgroundColor: "#e6f4ea"}}>✅ Growing collection of field-proven policies</td>
                        <td>🚧 Sample policies in documentation</td>
                        <td>✅ Ready-made policies available</td>
                    </tr>
                    <tr>
                        <td>Deployment for Event-Based Policies</td>
                        <td style={{backgroundColor: "#e6f4ea"}}>✅ 1 centralized Lambda per policy to reduce management overhead</td>
                        <td>🚧 Requires 1 Lambda per account per region per policy</td>
                        <td>🔒 Proprietary</td>
                    </tr>
                    <tr>
                        <td>Resource Tagging</td>
                        <td style={{backgroundColor: "#e6f4ea"}}>✅ tagging policy provided for 20 AWS resources, saving hundreds of hours of development and testing</td>
                        <td>🚧 Requires policies to be developed and tested for each resource</td>
                        <td>⛔ Not available.</td>
                    </tr>
                    <tr>
                        <td>Deployment with Infrastructure as Code (IaC)</td>
                        <td style={{backgroundColor: "#e6f4ea"}}>✅ IaC allows for predictable, auditable, and customizable infrastructure</td>
                        <td>🚧 Deployed by CLI</td>
                        <td>🚧 Not available. Requires development.</td>
                    </tr>
                    <tr>
                        <td>Deployment with Pipeline</td>
                        <td style={{backgroundColor: "#e6f4ea"}}>✅ Sample pipeline provided for GitHub Actions and GitLab CI for efficient, repeatable, and auditable deployment</td>
                        <td>🚧 Not available. Requires development.</td>
                        <td>🚧 Not available. Requires development.</td>
                    </tr>
                    <tr>
                        <td>Cloud Provider Support</td>
                        <td style={{backgroundColor: "#e6f4ea"}}>✅ Focused on AWS. Support for other cloud providers planned.</td>
                        <td>🏆 AWS, Azure, GCP</td>
                        <td>🔒 AWS only.</td>
                    </tr>
                    <tr>
                        <td>Dashboard</td>
                        <td style={{backgroundColor: "#e6f4ea"}}>🕙 Soon available on Li10.com</td>
                        <td>🚧💰 Requires development and/or expensive 3rd party tools</td>
                        <td>✅ Available</td>
                    </tr>
                    <tr>
                        <td>Support</td>
                        <td style={{backgroundColor: "#e6f4ea"}}>✅ Dedicated support from Li10 with optional review of your Pull Requests by the Li10 engineers</td>
                        <td>Open Source community or 3rd party support</td>
                        <td>💰Generic AWS Support for 3 to 10% of your cloud bill</td>
                    </tr>
                    <tr>
                        <td>Cost of operation</td>
                        <td style={{backgroundColor: "#e6f4ea"}}>✅ Best return on investment</td>
                        <td>🚧💰 Requires time for development and testing</td>
                        <td>💰 Can generate unreasonable costs.</td>
                    </tr>
                    <tr>
                        <td>Deployment models</td>
                        <td style={{backgroundColor: "#e6f4ea"}}>✅ Self-hosted. SaaS available on waiting list.</td>
                        <td>Self-hosted only</td>
                        <td>SaaS only</td>
                    </tr>
                </tbody>
            </table>

              </div>
            </div>

            <div className="col-md-12 col-lg-12 mb-12 mb-lg-12 aos-init aos-animate" data-aos="fade-up" id="consulting-services">
            <div className="unit-4 d-block solution-box-height">
              <this.SolutionHeader icon="robot" title="Consulting Services" />

              <p>
               Our team of senior cloud engineers has experience architecting and automating cloud solutions for startups
               and large Fortune 500 enterprises alike. We worked with the manufacturing, ecommerce, finance, hospitality and healthcare industries.
               </p>
               <p>You can use our expertise assess, design and implement solutions tailored to your needs.</p>
              </div>
            </div>

          </div>
        </div>
        </div>
      )
    }
}

export default Solutions;
