import Header from "../../components/Header";
import Navbar from "../../components/Navbar";
// import Cover from "../components/Cover";
// import Footer from "../components/Footer";


function TermsAndConditions() {
    return (
        <div>
        <Header />
        <Navbar />
        <div className="site-blocks-cover">
          <div className="container" style={{paddingTop: "80px"}}>
            <div className="row align-items-center justify-content-center">
              <div className="col-md-12">
                {/* <div className="d-none d-lg-block">
                  <img src={temp} title="Li10 footprint" alt="Li10 footprint" className="img-fluid img-absolute"></img>
                </div> */}
                <div className="row mb-4">
                  <div className="d-flex align-items-start flex-column">
                    <h1>Terms & Conditions</h1>
                    <p>The following terms and conditions, set forth in this document (the “Terms of Use”), apply to all visitors or users (each a “User”) of this web site (the “Site”). By accessing or using this Site, the User acknowledges acceptance of these Terms of Use. Li10 Labs UG (”The Company“) reserves the right to change, modify, or update these Terms of Use from time to time at its sole discretion without notice, and the User’s continued use of the Site signifies his or her acceptance of the revised Terms of Use. In case of any violation of these Terms of Use, The Company reserves the right to seek all remedies available by law and in equity for such violations. These Terms of Use apply to all visits to the Site, both now and in the future.</p>

                    <h2>General</h2>
                    <p>The content appearing on this Site, including without limitation, information, documents, images and software is for the User’s general information, subject to the Terms of Use. The User will not use the Site for commercial purposes and in any way that is unlawful, or may harm The Company, its affiliates, suppliers and customers or any third parties. The User may not use the Site in any manner that could damage, disable, overburden and/or circumvent any security measure of, or impair the Site (or the network(s) connected to the Site) or interfere with any other party’s use of the Site.</p>
                    <p>The design, layout and content of this Site (the “Materials”) are the sole property of The Company and are protected by copyright, trademark, patent or other intellectual property laws. Except as stated herein, none of the Materials may be copied, reproduced, distributed, republished, downloaded, displayed, posted or in any form or by any means, including but not limited to electronic, mechanical, photocopying, recording, or other means, without the prior express written permission of The Company, as applicable. Except where expressly provided otherwise by The Company, nothing in this Site shall be construed to confer any license under any of The Company intellectual property rights, whether by estoppel, implication, or otherwise. Users are encouraged to read the “Contact Us” section below if they have any questions about obtaining such licenses.</p>
                    <p>The Company hereby grants Users permission to display, copy, distribute and download certain Materials from this Site as specified in the Site, provided that: (1) both the copyright notice identified above and this permission notice appear in the Materials; (2) the use of such Materials is solely for personal, non-commercial and informational use and Such Materials will not be copied or posted on any networked computer, broadcast in any media, or used for commercial gain; and (3) the Materials are not modified in any way. This permission terminates automatically without notice if User breaches any of these terms or conditions. Upon termination, User will immediately destroy any downloaded or printed Materials.</p>
                    <p>Any unauthorized use of any Materials contained on this Site may violate copyright laws, trademark laws, the laws of privacy and publicity, and communications regulations and statutes.</p>

                    <h2>Trademarks</h2>
                    <p>The trademarks, logos and service marks (collectively: the “Marks“) displayed on this Site are the property of The Company or other third parties. Users are not permitted to use these Marks without the prior written consent of The Company or such third party which may own the Mark.</p>

                    <h2>Software</h2>
                    <p>The use of any software made available on this Site or any software associated with this Site will be governed by the terms and conditions of the license accompanying such software.</p>

                    <h2>Links to Third Party Sites</h2>
                    <p>This Site may contain hyperlinks, which will take Users out of the Site and connect them to third party sites. The Company is providing these hyperlinks to Users only as a matter of convenience. The inclusion of any hyperlink does not imply endorsement by The Company of the linked site. These linked sites are not under the control of The Company and by accessing them, User does so at his or her own risk.</p>

                    <h2>Additional Terms and Unenforceability</h2>
                    <p>The Site may itself contain additional terms (for example, End User License Agreements, whether relating to The Company’s software and/or products or to any of its affiliates’ software and/or products) that further govern the use of that Site or Materials therein. If any terms contained in this Terms of Use agreement conflict with any terms contained within the Site, then the terms which are more restrictive upon the User shall apply. If any provision of this Terms of Use</p>
                    <p>Agreement is held to be unenforceable in any jurisdiction for any reason, such provision shall be reformed only to the extent necessary to make it enforceable, and such decision shall not affect the enforceability of such provision under other circumstances, or of the remaining provisions hereof under all circumstances.</p>

                    <h2>Pricing Policy</h2>
                    <p>The Company may change the price for any license or subscription plan at any time and will communicate these price changes to Users by email at least 15 days before the price change. Price changes for active subscriptions will take effect at the start of the next subscription period following the date of the price change. If a User does not agree with the price changes, the User has the right to reject the change by cancelling active subscriptions prior to renewal. Please make sure to read any such notification of price changes carefully.</p>

                    {/* <h2>Delivery Policy</h2> */}

                    <h2>Subscription Termination</h2>
                    <p>Subscription renewal is automatic; customers must opt out of subscription renewal. To terminate renewal, send us an email at matt@li10.com</p>

                    <h2>Refund Policy</h2>
                    <p>Refund requests for licenses and subscription renewals will be accepted up to a maximum of fourteen (14) days post-purchase.</p>
                    <p>To request a refund for a purchase made over the Internet, send an email to matt@li10.com containing your purchase details.</p>

                    <h2>Disclaimer</h2>
                    <p>THE MATERIALS MADE AVAILABLE ON THIS SITE ARE PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESSED OR IMPLIED, OR CONDITIONS OF ANY KIND WHETHER ARISING BY LAW, USAGE, A COURSE OF DEALING OR TRADE PRACTICE.</p>
                    <p>The incorporation of (new) product attributes, as such may be discussed in Materials posted on this Site, into any release or upgrade of any The Company software or hardware product—as well as the timing of any such release or upgrade—is at the sole discretion of The Company.</p>

                    <h2>Governing Laws and Jurisdiction</h2>
                    <p>This Site is controlled by The Company in Düsseldorf, Germany. By accessing this Site, the User agrees that any legal matter that may arise between him and The Company relating to the Materials of this Site and to his or her usage of this Site shall be governed by the German laws, without giving effect to principles of conflicts of law, and determined exclusively by the competent courts in Germany.</p>

                    <h2>Limitation of Liabilities; Claim Bar</h2>
                    <p>THE COMPANY AND ITS AFFILIATES SHALL NOT BE LIABLE FOR ANY DAMAGES SUFFERED AS A RESULT OF USING, MODIFYING, CONTRIBUTING, COPYING, DISTRIBUTING, OR DOWNLOADING THE MATERIALS. IN NO EVENT SHALL THE COMPANY OR ITS AFFILIATES BE LIABLE FOR ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE (INCLUDING BUT NOT LIMITED TO LOSS OF BUSINESS, REVENUE, PROFITS, USE, DATA OR OTHER ECONOMIC ADVANTAGE), HOWEVER IT ARISES, WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF INFORMATION AVAILABLE FROM THIS SITE, EVEN IF THE COMPANY OR ITS AFFILIATES HAVE BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. THE USER BEARS THE SOLE RESPONSIBILITY FOR THE ADEQUATE PROTECTION AND BACKUP OF DATA AND/OR EQUIPMENT USED IN CONNECTION WITH THE SITE AND THE USER WILL NOT MAKE A CLAIM AGAINST THE COMPANY OR ITS AFFILIATES FOR LOST DATA, RE-RUN TIME, INACCURATE OUTPUT, WORK DELAYS, OR LOST PROFITS RESULTING FROM THE USE OF THE MATERIALS. THE USER AGREES TO HOLD THE COMPANY AND ITS AFFILIATES HARMLESS FROM, AND THE USER COVENANTS NOT TO SUE THE COMPANY OR ITS AFFILIATES FOR, ANY CLAIMS BASED ON OR RELATED TO THE USE OF THE SITE.</p>
                    <p>IF THE USER DOES NOT AGREE WITH ANY PART OF THE TERMS OF USE, OR HE OR SHE HAS ANY OTHER DISPUTE OR CLAIM WITH OR AGAINST THE COMPANY WITH RESPECT TO THE TERMS OF USE OR THE SITE, THEN HIS OR HER SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.</p>

                    <h2>Contact Us</h2>
                    <p>We welcome Users’ comments with respect to The Company Terms of Use. Any comments or questions should be sent by e-mail to matt@li10.com.</p>
                    {/* <div>
                      <a href="https://app.li10.com" className="btn btn-primary mr-2 mb-2"><b>Get started now!</b></a>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
        </div>
    );
}
export default TermsAndConditions;
