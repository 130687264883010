import React from "react";

class Navbar extends React.Component {
    render() {
      return (
        <div id="sticky-wrapper" className="sticky-wrapper data-sticky-container">
        <header className="site-navbar py-md-4 site-navbar-target shrink" role="banner">
        <div className="container">
        <div className="row align-items-center">
        <div className="col-12 col-md-12 main-menu">
          <nav className="site-navigation position-relative text-right" role="navigation">
            <ul className="site-menu main-menu js-clone-nav mr-auto d-none d-lg-block">
              <li><a href="/" className="nav-link"><b>Home</b></a></li>
              <li><a href="#solutions" className="nav-link"><b>Solutions</b></a></li>
              <li><a href="#pricing" className="nav-link"><b>Pricing</b></a></li>
              <li><a href="#about" className="nav-link"><b>About Us</b></a></li>
              <li><a href="#contact" className="nav-link"><b>Contact</b></a></li>
              {/* <li><a href="/blog" className="nav-link">Blog</a></li> */}
              <li><div><a href="https://app.li10.com" className="btn btn-primary mr-2 mb-2"><b>Login</b></a></div></li>
            </ul>
          </nav>
        </div>
        </div>
        </div>
        </header>
      </div>
      );
    }
}

export default Navbar;
